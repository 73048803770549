import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={process.env.PUBLIC_URL + 'NCA_Colour_Logo.svg'} className="App-logo" alt="logo" />
        <p>
          Phone: <a className="App-link" href="tel: (07) 3901 2622">(07) 3901 2622</a>
        </p>
        <p>
          Email: <a className="App-link" href="mailto: admin@nca.training">admin@nca.training</a>
        </p>
      </header>
    </div>
  );
}

export default App;
